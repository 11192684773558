import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Dialog,
  TextField,
  InputAdornment,
  Hidden,
  SvgIcon,
  Drawer,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Menu as MenuIcon } from "react-feather";
import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import { AuthContext } from "src/context/Auth";
import { MdOutlinePriceChange } from "react-icons/md";
import { UserContext } from "src/context/User";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { FaAngleRight } from "react-icons/fa6";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#232B3B;",
  },

  logo: {
    maxWidth: "200px",
    // marginRight: theme.spacing(2),
    "@media(max-width:599px)": {
      maxWidth: "120px",
    },
  },

  searchBox: {
    // "@media(max-width:900px)": {
    //   display: "none",
    // },
  },

  linkButton: {
      cursor: "pointer",
    // display: "flex",
    gap: "10px",
    color:"white",
    fontWeight:600,
    backgroundColor:"transparent",
    justifyContent: "space-evenly",
    alignItems: "center",
    minWidth: "120px",
    padding: "17px 26px",
    "&:hover":
    {
      color:"#D19A1D"
    }
  },  linkButtonIcon: {
      cursor: "pointer",
    // display: "flex",
    // gap: "10px",
    color:"white",
    // fontWeight:600,
    backgroundColor:"transparent",
    border:"1px solid #D19A1D",
    justifyContent: "space-evenly",
    alignItems: "center",
    minWidth: "60px",
    paddingLeft:"26px",
    paddingRight:"26px",
    // padding: "17px 26px",
    "&:hover": {
      backgroundColor:"#D19A1D"
    }
  },

  customSelectBox: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    justifyContent: "space-evenly",
    alignItems: "center",
    minWidth: "120px",
    background: "#D19A1D",
    padding: "22px 26px",
    "& .contentTypo": {
      color: "rgb(23, 38, 36)",
      display: "flex",
      textTransform: "uppercase",
      fontFamily: "'Noto Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "16px",
      cursor: "pointer",
    },
  },
  toolbar: {
    paddingRight: "0px !important",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);

  return (
    <AppBar
      style={{
        backgroundColor: "#172624",
        height: "64px",
        justifyContent: "center",
        borderBottom: "1px solid rgba(247, 246, 241, 0.20)",
      }}
    >
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const themeSeeting = useContext(SettingsContext);
  const user = useContext(UserContext);
  const [unReadNotification, setUnReadNotification] = useState(0);
  const [isloading, setIsloading] = useState(false);
  const [notiData, setNotiData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const confirmationHandler = () => {
    setOpen(false);
    navigate("/login");
    auth.userLogIn("", false);
    handleLogOut();
    window.localStorage.removeItem("token");
    window.localStorage.clear();
  };

  const handleLogOut = async (values) => {
    try {
      const res = await Axios.post(
        ApiConfig.logout,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200 || res.status === 201) {
        user.cleanProfile();
      }
    } catch (error) {
      console.log("ERROR", error?.response?.data?.error);
      // Handle error here
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const notificationHandler = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.listNotification,
        params: { limit: 5 },
        headers: {
          token: token,
        },
      });

      if (res.data.statusCode == 200) {
        setNotiData(res?.data?.dataResults?.docs);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const [count, setCount] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <img
          className={classes.logo}
          src="/images/Logo.png"
          onClick={() => {
            navigate("/");
          }}
        />
        <div
          style={{
            height: "64px",
            borderLeft: "1px solid rgba(247, 246, 241, 0.20)",
          }}
        ></div>
        <img
          src="images/House.svg"
          alt="#"
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        />
        <Button
          className={classes.linkButton}
          onClick={() => {
            navigate("/map", {
              state: {
                mapView: "map",
              },
            });
          }}
        >
          <span> Go to Map</span>
        </Button>
      </div>
      <>
        <Box flexGrow={1} />
        <Box className={classes.searchBox}>
          <Box
            className={`${classes.customSelectBox}`}
            onClick={() => setOpen(true)}
          >
            <p className="contentTypo">Logout</p>
            <FaAngleRight
              style={{ fontSize: "20px", color: "rgb(0, 0, 0, 0.8)" }}
            />
          </Box>
        </Box>
      </>

      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          isloading={isloading}
        />
      )}
    </>
  );
}
